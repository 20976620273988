import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/pages/IndexPage.vue')
  },
  {
    path: '/subcategory/:id',
    name: 'subcategory-detail',
    component: () => import('@/pages/SubcategoryPage.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/pages/SearchPage.vue')
  },
  {
    path: '/basket',
    name: 'basket',
    component: () => import('@/pages/BasketPage.vue')
  },
  {
    path: '/editor',
    name: 'editor',
    component: () => import('@/pages/EditorPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
