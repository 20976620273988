<template>
  <router-view v-if="is_web_app_available" class="pb-24"/>
  <div v-else class="min-h-screen flex justify-center items-center">
    <div class="text-2xl text-center"><el-icon class="animate-spin"><RefreshRight /></el-icon></div>
  </div>
  <div class="fixed left-0 bottom-0 right-0 bg-white dark:bg-[#111111] rounded-t-md shadow-[0_0_7px_0_rgba(0,0,0,0.25)] z-40">
	<p v-if="is_first_order" class="text-xs pb-2 text-gray-400">{{ user.text.first_order_delivery_will_be_free }}</p>
	<p v-if="is_web_app_available && order_items.length && !now_is_delivery_available_time" class="text-xs pb-2 text-gray-400">{{ user.text.delivery_time_up }}</p>
	<p v-if="is_web_app_available && order_items.length && now_is_jumah" class="text-xs pb-2 text-gray-400">{{ user.text.delivery_time_up_because_now_is_jumah }}</p>
	<router-link 
		v-if="user.is_owner && $router.currentRoute.value.path === '/'"
		:to="{name: 'editor', query: {quarter_id, longitude, latitude}}"
	>
		<button class="w-full bg-blue-600 rounded-2xl py-2 m-2 text-white font-semibold">{{ user.text.change_product_price }}</button>
	</router-link>
	<router-link 
		v-if="is_web_app_available && order_items.length && $router.currentRoute.value.path !== '/basket' && now_is_delivery_available_time && !now_is_jumah"
		:to="{name: 'basket', query: {quarter_id, longitude, latitude}}"
	>
		<button class="w-full bg-indigo-600 rounded-2xl py-2 m-2 text-white font-semibold">{{ user.text.go_to_basket }} ({{ order_total }} {{ user.text.som }})</button>
	</router-link>
	<button 
		v-else-if="is_web_app_available && order_items.length && $router.currentRoute.value.path !== '/basket' && (!now_is_delivery_available_time || now_is_jumah)"
		class="w-full bg-gray-500 rounded-2xl py-2 m-2 text-white font-semibold"
	>{{ user.text.go_to_basket }} ({{ order_total }} {{ user.text.som }})</button>
  </div>
</template>

<script>
	import axios from 'axios'
	import store from '@/store'

	export default {
		data() {
			return {
				is_web_app_available: false,
				now_is_delivery_available_time: true,
				now_is_jumah: false,
				web_view: window.Telegram.WebView,
				web_app: window.Telegram.WebApp,
			}
		},
		watch: {
			selected_shop(newOne, oldOne){
                if(newOne !== oldOne){
                    if(newOne.server_hour < newOne.delivery_start_hour || newOne.server_hour >= newOne.delivery_end_hour){
						this.now_is_delivery_available_time = false;
					}else{
						this.now_is_delivery_available_time = true;
					}
                }
            }
		},
		computed: {
			quarter_id(){return store.state.quarter_id},
            longitude(){return store.state.longitude},
            latitude(){return store.state.latitude},
			user(){return store.state.user},
			selected_shop(){return store.state.selected_shop},
			is_first_order(){return store.state.is_first_order},
			order_items(){return store.state.order_items},
			order_total(){
				let total = 0;
				store.state.order_items.forEach(order_item => {total += order_item.amount * order_item.product_price.price});
				return total;
			}
		},
		mounted(){
			this.web_app.expand();
			document.getElementsByTagName('html')[0].classList.add(this.web_app.colorScheme);
			window.Telegram.WebApp.BackButton.onClick(() => {this.$router.go(-1)});
			if(JSON.stringify(this.web_view.initParams) !== '{}'){
				this.$router.isReady().then(() => {
					store.commit('setQuarterId', this.$route.query.quarter_id);
					store.commit('setLongitude', this.$route.query.longitude);
					store.commit('setLatitude', this.$route.query.latitude);
					this.loadUserInfo();
					this.loadShops();
					this.is_web_app_available = true;
				})
			}
			String.prototype.format = function (args) {
				var text = this
				for(var attr in args){
					text = text.split('{' + attr + '}').join(args[attr]);
				}
				return text
			};
		},
		methods: {
            async loadUserInfo(){
                await axios.get('get-user-info/').then((response) => {
					store.commit('setUserInfo', response.data);
                    store.commit('setIsFirstOrder', response.data.is_first_order);
                }).catch((error) => {
                    console.log(error);
                })
            },
            async loadShops(){
                await axios.get('get-quarter-shops/?quarter_id=' + this.$route.query.quarter_id).then((response) => {
					store.commit('setShops', response.data);
					store.commit('setSelectedShop', response.data[0]);
					let selected_shop = response.data[0];
					if(selected_shop.server_hour < selected_shop.delivery_start_hour || selected_shop.server_hour >= selected_shop.delivery_end_hour){
						this.now_is_delivery_available_time = false;
					}
					let now = new Date()
					if(now.getDay() == 5 && (selected_shop.server_hour == 12 || selected_shop.server_hour == 13)){
						this.now_is_jumah = true;
					}
                }).catch((error) => {
                    console.log(error);
                })
            },
		}
	}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

* {
	font-family: 'Comfortaa', cursive;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}
</style>
