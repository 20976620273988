import { createStore } from 'vuex'

export default createStore({
  state: {
    quarter_id: null,
    longitude: null,
    latitude: null,
    user: null,
    is_first_order: false,
    shops: [],
    selected_shop: null,
    order_items: [],
  },
  getters: {
  },
  mutations: {
    setQuarterId(state, quarter_id){state.quarter_id = quarter_id},
    setLongitude(state, longitude){state.longitude = longitude},
    setLatitude(state, latitude){state.latitude = latitude},
    setUserInfo(state, user){state.user = user},
    setIsFirstOrder(state, is_first_order){state.is_first_order = is_first_order},
    setShops(state, shops){state.shops = shops},
    setSelectedShop(state, selected_shop){
      state.selected_shop = selected_shop;
      state.order_items = [];
    },
    changeOrderItemAmount(state, {product_price, is_increase_action}){
      let found_index = state.order_items.findIndex(order_item=>order_item.product_price.id === product_price.id)
      if(found_index !== -1){
        if(is_increase_action){
          state.order_items[found_index].amount++;
        } else {
          state.order_items[found_index].amount--;
        }
        if(state.order_items[found_index].amount === 0){
          state.order_items = state.order_items.filter(order_item => order_item.product_price.id !== product_price.id);
        }
      } else {
        state.order_items.push({
          product_price,
          amount: 1,
        })
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
