import axios from "axios"

let web_app = window.Telegram.WebApp;
axios.defaults.baseURL = '/api/';
axios.defaults.headers["Authorization"] = `${web_app.initDataUnsafe.hash} ${web_app.initData}`
axios.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        return Promise.reject(err);
    }
)